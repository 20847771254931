.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    z-index: 1000;
};

.modal-blur {
    backdrop-filter: blur(30px);
}
