.generating-text {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;
  color: #777580
}

.generating-box {
  border: 4px dashed #313131;
  border-radius: 8px;
}

.like-icon {
  fill: #313131;
}