.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: 0.5%; /* gutter size offset */
    width: auto;
  }
.my-masonry-grid_column {
    padding-left: 0.5%; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: black;
    margin-bottom: 2%;
}