@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 0%;
        --foreground: 0 0% 100%;

        --muted: 240 6% 10%;
        --muted-foreground: 0 0% 77%;

        --popover: 0 0% 5%;
        --popover-foreground: 0 0% 100%;

        --border: 0 1% 21%;
        --input: 0 1% 21%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --primary: 0 100% 64%;
        --primary-foreground: 0 0% 100%;

        --secondary: 0 1% 21%;
        --secondary-foreground: 0 0% 100%;

        --accent: 240 5% 26%;
        --accent-foreground: 0 0 100%;

        --destructive: 0 60% 29%;
        --destructive-foreground: 0 100% 88%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    /* HTML */
    * {
        @apply border-border;
    }

    body {
        @apply m-0 bg-background text-foreground font-quicksand;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }

    /* https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
        appearance: textfield; /* Firefox */
        -moz-appearance: textfield; /* Firefox */
    }
}

@layer components {
    .btn {
        @apply flex gap-2 items-center px-4 py-2 rounded hover:cursor-pointer opacity-90 hover:opacity-100;
    }

    .btn-primary {
        @apply bg-primary-500 text-black font-semibold;
    }

    .btn-secondary {
        @apply bg-zinc-700 text-white;
    }

    .btn-form {
        @apply py-3;
    }

    .btn-icon {
        @apply h-4 w-4;
    }

    .input-control {
        @apply px-4 py-3 bg-zinc-800 outline-none rounded w-full file:text-sm file:bg-transparent file:border-none file:text-white/70 file:hover:text-white file:cursor-pointer appearance-none;
    }
}

@layer utilities {
    /* width */
    .scrollbar::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    .scrollbar::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    .scrollbar::-webkit-scrollbar-thumb {
        @apply rounded-full bg-zinc-700;
    }

    /* Handle on hover */
    .scrollbar::-webkit-scrollbar-thumb:hover {
        @apply bg-zinc-600;
    }

    .scrollbar-area.scrollbar::-webkit-scrollbar-thumb {
        @apply md:bg-zinc-600/0 ;
    }

    .scrollbar-area:hover.scrollbar::-webkit-scrollbar-thumb {
        @apply md:bg-zinc-800;
    }
}

@keyframes outlineAnimation {
    0%, 100% {
        box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.7);
    }
    50% {
        box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
    }
}

@keyframes strokeDash {
    to {
        stroke-dashoffset: 140;
    }
}